import { Store } from 'vuex'
import { createStore as createVueStore } from 'vuex-extensions'
import main from '@/store/modules/main'
import catalog from '@/store/modules/catalog'
import auth from '@/store/modules/auth'
import articles from '@/store/modules/articles'
import files from '@/store/modules/files'
import basket from '@/store/modules/basket'
import users from '@/store/modules/users'
import support from '@/store/modules/support'
import reporting from '@/store/modules/reporting'
import documents from '@/store/modules/documents'
import invoicesOlsa from '@/store/modules/invoicesOlsa'
import contracts from '@/store/modules/contracts'
import estratto from '@/store/modules/estratto'
import { ICatalogState } from '@/store/modules/catalog/interfaces/ICatalogState'
import { IAuthState } from '@/store/modules/auth/IAuthState'
import { IMainState } from '@/store/modules/main/intefaces/IMainState'
import { IArticlesState } from '@/store/modules/articles/interfaces/IArticlesState'
import { IEstrattoState } from '@/store/modules/estratto/interfaces/IEstrattoState'
import orders, { IOrdersState } from './modules/orders'
import common, { ICommonState } from './modules/common'
import links from './modules/links'
import tickets from './modules/tickets'
import helpPages from './modules/help-pages'
import { IBasketState } from './modules/basket/interfaces/IBasketState'
import { IUsersState } from '@/store/modules/users/interfaces/IUsersState'
import { IFilesState } from '@/store/modules/files/interfaces/IFilesState'
import { ISupportState } from '@/store/modules/support/interfaces/ISupportState'
import { IReportingState } from '@/store/modules/reporting/interfaces/IReportingState'
import { ILinksState } from '@/store/modules/links/interfaces'
import { IHelpPagesState } from './modules/help-pages/interfaces'
import { IDocumentsState } from '@/store/modules/documents/interfaces/IDocumenstState'
import { IInvoicesOlsaState } from './modules/invoicesOlsa/interfaces/IInvoicesOlsaState'
import { IContractsState } from './modules/contracts/interfaces/IContractsState'
import { ITicketsState } from '@/store/modules/tickets/interfaces/ITicketsState'
import { GTM_SUGGESTED_CART, SUGGESTED_CART_ORDER_PLACED_WITH_MODIFICATION } from '@/utils/gtmConstants'

export interface IRootState {
  main: IMainState
  catalog: ICatalogState
  auth: IAuthState
  orders: IOrdersState
  articles: IArticlesState
  files: IFilesState
  common: ICommonState
  basket: IBasketState
  users: IUsersState
  support: ISupportState
  reporting: IReportingState
  links: ILinksState
  tickets: ITicketsState
  helpPages: IHelpPagesState
  documents: IDocumentsState
  invoicesOlsa: IInvoicesOlsaState
  contracts: IContractsState
  estratto: IEstrattoState
}

export default function createStore (cache: any): Store<IRootState> {
  const options = {
    modules: {
      main,
      catalog,
      orders,
      auth,
      articles,
      files,
      common,
      basket,
      users,
      support,
      reporting,
      links,
      tickets,
      helpPages,
      documents,
      invoicesOlsa,
      contracts,
      estratto
    },
    getters: {
      getCache (): any {
        return cache
      }
    },
    actions: {
      logout () {
        const that = this as any
        sessionStorage.removeItem('id_token')
        localStorage.removeItem('deliveryDate')
        localStorage.removeItem('shipToId')
        localStorage.removeItem(SUGGESTED_CART_ORDER_PLACED_WITH_MODIFICATION)
        localStorage.removeItem(GTM_SUGGESTED_CART)
        caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
        that.reset()
      }
    }
  }

  return createVueStore(Store, options)
}
